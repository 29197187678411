export const pagesConstants = {
    PAGES_LIST_REQUEST: 'PAGES_LIST_REQUEST',
    PAGES_LIST_SUCCESS: 'PAGES_LIST_SUCCESS',
    PAGES_LIST_FAILURE: 'PAGES_LIST_FAILURE',
    
    PAGE_REQUEST: 'PAGES_SGL_REQUEST',
    PAGE_SUCCESS: 'PAGES_SGL_SUCCESS',
    PAGE_FAILURE: 'PAGES_SGL_FAILURE',

    PAGE_UPDT_REQUEST: 'PAGES_UPDT_REQUEST', 
    PAGE_UPDT_SUCCESS: 'PAGES_UPDT_SUCCESS',
    PAGE_UPDT_FAILURE: 'PAGES_UPDT_FAILURE',


    PAGE_DLT_REQUEST: 'PAGES_DLT_REQUEST', 
    PAGE_DLT_SUCCESS: 'PAGES_DLT_SUCCESS',
    PAGE_DLT_FAILURE: 'PAGES_DLT_FAILURE',
}