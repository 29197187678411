export const partnersConstants = {
    REPORTS_TYPE: 'REPORTS_TYPE',
    
    WYBY_LIST_REQUEST: 'WYBY_LIST_REQUEST',
    WYBY_LIST_SUCCESS: 'WYBY_LIST_SUCCESS',
    WYBY_LIST_FAILURE: 'WYBY_LIST_FAILURE',

    CLI_LIST_REQUEST: 'CLI_LIST_REQUEST',
    CLI_LIST_SUCCESS: 'CLI_LIST_SUCCESS',
    CLI_LIST_FAILURE: 'CLI_LIST_FAILURE',


    CLI_TOP_REQUEST: 'CLI_TOP_REQUEST',
    CLI_TOP_SUCCESS: 'CLI_TOP_SUCCESS',
    CLI_TOP_FAILURE: 'CLI_TOP_FAILURE',
};