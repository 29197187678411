export const authConstants = {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGIN_CONFIRM: 'LOGIN_CONFIRM',
    LOGIN_CONFIRM_FAIL: 'LOGIN_CONFIRM_FAIL',
    
    LOGOUT: 'LOGOUT',
};